html,
body,
#root,#login {
    width: 100%;
    height: 100%;
}

.skiptranslate {
    visibility: collapse;
}

.skiptranslate>div {
    visibility: visible;
}

.skiptranslate>span {
    display: none;
}